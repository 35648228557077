import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4-canary.27_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_vr7tq6ssulfizbnvpt5dzs4fau/node_modules/next/dist/lib/metadata/metadata-boundary.js");
